@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=DM+Sans&display=swap&css');
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

.cabin {
  font-family: 'Cabin', sans-serif !important;
  font-style: normal;
}

.cabin-bold {
  font-family: 'Cabin', sans-serif !important;
  font-weight: 700;
}

.text-white {
  color: white;
}

.roboto {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
}

.plans_disclaimer a{
  text-decoration: none;
  color: #FD45D9;
  font-size: 14px;
}

.baloo2 {
  font-family: 'Baloo 2', cursive !important;
}

.mulish {
  font-family: 'Mulish', sans-serif !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 5px !important;
}
@font-face {
  font-family: 'Scotia App';
  src: local('Scotia App Headline'),
    url(../fonts/scotia/Scotia_A_Headline.ttf) format('truetype');
  font-display: swap;
}

.input-checkk {
  position: absolute;
  right: 1.5%;
  top: calc(50% - 18.63px / 2 - 0px);
}

.button-text {
  font-size: 20px;
}

.w-95 {
  width: 95% !important;
}

.w-48 {
  width: 48.2% !important;
}

.pointer {
  cursor: pointer;
}

.input-custom-icon {
  position: absolute;
  right: 1.5%;
  top: 15%;
  width: 4%;
}
.scotia {
  font-family: 'Scotia App', sans-serif !important;
  font-style: normal;
}

.helvetica {
  font-family: Eina, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
}

.sans-pro {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.monserrat {
  font-family: 'Montserrat' !important;
}

.dm-sans {
  font-family: 'DM Sans', sans-serif !important;
}

.inter {
  font-family: 'Inter', sans-serif !important;
  font-style: normal;
}

.inter-bold {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.chatheader-container {
  left: auto;
  width: 711px !important;
  max-width: 711px;
  top: 0;
  position: fixed;
  background-color: #ffffff;
  z-index: 5;
}
.wo-description-container {
  margin-top: 20%;
}
.messageWidth {
  width: 75%;
}
@keyframes move {
  50% {
    top: 200px;
    left: 130px;
  }
}

#animate {
  animation: move 3s ease infinite;
}

.dateTam {
  width: 30%;
}
.SelectAuto {
  width: 95%;
}
.share-win-container {
  padding: 0px 10%;
}

@media (max-width: 1200px) {
  .wo-description-container {
    margin-top: 15%;
  }
}
.share-win-container {
  padding: 0px 10%;
}
@media (max-width: 767px) {
  .dateTam {
    width: 100%;
  }
  .share-win-container {
    padding: 0%;
  }
  .ul-admin {
    padding-left: 1rem !important;
  }
  .banner-feed-container {
    width: 272px !important;
    margin: auto;
  }
  .paddinBChubb {
    top: 0;
  }
  .dateChubb {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 103.33px;
    height: 56px;
    border-radius: 16px;
  }
  .input-checkk {
    width: 5.5%;
    right: 3%;
    top: 25%;
  }

  .button-text {
    font-size: 7px;
  }

  .input-custom-icon {
    width: 5.5%;
    right: 5%;
    top: 25%;
  }

  .SelectAuto {
    width: 100%;
  }
  .chatheader-container {
    max-width: 711px;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #ffffff;
    z-index: 5;
  }
  .wo-description-container {
    margin-top: 8%;
  }
  .linknav-lp {
    text-align: right !important;
  }
  .messageWidth {
    width: 80%;
  }
  .products-carousel .rec .rec-slider {
    padding-right: 503px !important;
  }

  /* .products-carousel .rec .rec-carousel-item-0 {
     width: 62vw !important;
   } */
  /*  .products-carousel .rec .rec-carousel-item-1 {
     width: 70vw !important;
     margin-left: 10 !important;
   } */

  .products-carousel .rec .rec-item-wrapper {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .heart-container img {
    max-width: 22px !important;
  }
  .advisoy {
    font-size: 13px;
  }
}

.nmr-left20 {
  margin-left: -20px;
}
.product-request {
  font-size: 14px;
}
@media (max-width: 350px) {
  .product-request {
    font-size: 13px;
    padding-right: 1rem !important;
  }
  .advisory-container {
    padding: 0px !important;
  }
}
@media (max-width: 300px) {
  .product-request {
    font-size: 11px;
  }
  .hearts-container {
    padding-right: 0px !important;
  }
}

@media (min-width: 992px) {
  .kanda-world-container {
    width: 670px !important;
  }
  .kanda-world-avatar {
    left: 43% !important;
  }
  .paddinBChubb {
    padding-top: 0px;
  }
}

.products-detail-table {
  width: 100%;
}

@media (min-width: 768px) {
  .banner-ci-container {
    width: 470px;
  }
  .products-detail-table {
    position: absolute;
    left: 20%;
    width: 80%;
    top: 5%;
  }
  /* .products-carousel .rec .rec-slider {
    padding-right: 503px !important;
   } */

  /* .products-carousel .rec .rec-carousel-item-0 {
     width: 25vw !important;
   } */
  /* .products-carousel .rec .rec-carousel-item-1 {
     width: 70vw !important;
   } */

  .products-carousel .rec .rec-item-wrapper {
    width: 100% !important;
    padding-left: 0px !important;
  }
  #cardplft .rec .rec-carousel {
    height: 300px !important;
    width: 100% !important;
  }
}

.go-bottom {
  position: fixed;
  right: 0;
  bottom: 8%;
  z-index: 1;
}

.loaderSplash {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0076ce;
  border-right: 16px solid #aece0f;
  border-bottom: 16px solid #0076ce;
  border-left: 16px solid #aece0f;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*  */
