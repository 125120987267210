html {
    height: 100%;
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'TheHistoriaDemo';
  src: url('./assets//fonts//the-historia//TheHistoriaDemo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  src: local("Cabin-VariableFont"), local("Cabin-VariableFont"),
    url(./assets/fonts/Cabin/Cabin-VariableFont_wdth\,wght.ttf) format("truetype") ;
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
